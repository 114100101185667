import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en/translation.json'
import de from './de/translation.json'

const resources = {
  de: { translation: de },
  en: { translation: en },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    preload: resources,
    fallbackLng: 'de',
    supportedLngs: ['de', 'en'],
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator', 'path'],
      lookupLocalStorage: 'wohnvoll-locale',
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // react-i18next options
    react: {
      useSuspense: false,
    },
  })
  .then(t => {
    if (typeof window !== 'undefined') {
      const lang =
        localStorage.getItem('wohnvoll-locale').split('-')[0] ||
        navigator.languages[0].split('-')[0]
      if (window.location.pathname === '/' && lang !== 'de') {
        if (Object.hasOwnProperty.call(resources, lang)) {
          localStorage.setItem('wohnvoll-locale', lang)
          window.location.pathname = `/${lang}`
        }
      }
    }
  })

export default i18n
