import React from 'react'
import { oneLine, stripIndent } from 'common-tags'
import i18next from './src/locale/i18next'
import { I18nextProvider } from 'react-i18next'
import { PageContextProvider } from './src/context/page-context'

const generateGTM = (id: string) => stripIndent`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

export function onRenderBody({ pathname, setHeadComponents }) {
  let scripts = [
    <script
      key="usercentrics-cmp"
      id="usercentrics-cmp"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      data-settings-id="P1bjZsOAj"
      async
    />,
    <script
      type="text/plain"
      data-usercentrics="Google Tag Manager"
      key="plugin-google-tagmanager"
      dangerouslySetInnerHTML={{
        __html: oneLine`${generateGTM('GTM-NVXCC92')}`,
      }}
    />,
  ]

  const isApp =
    pathname.includes('/app-terms') || pathname.includes('/app-privacy-policy')

  if (isApp) {
    scripts = []
  }

  setHeadComponents(scripts)
}
