import './src/styles/global.css'
export { wrapPageElement, wrapRootElement } from './gatsby-ssr'

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash === '') {
    window.setTimeout(() => window.scrollTo(0, 0), 100)
  } else {
    const element = document.getElementById(location.hash.replace('#', ''))
    if (element) {
      element.scrollIntoView()
    }
  }

  return false
}
